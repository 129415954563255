import logo from "../assets/logo.svg";
import "./app.css";

export function App() {
  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} className="app-logo" alt="logo" />
        <p>Garkbit</p>
      </header>
    </div>
  );
}
